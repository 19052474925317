import React from "react";
import PropTypes from "prop-types";
import { COLORS } from "utils/styles";
import PATHS from "utils/paths";
import { getConfig } from "utils/env";
import { usePageEditMode } from "utils/hooks";
import Analytics, { CLICK_MADE_WITH } from "utils/analytics";
import { interpolateRouteWithParams } from "utils/route";
import { SECTIONS } from "components/FormerEditor/utils";
import { PHONE } from "utils/constants/ui";

const MadeWith = () => {
  const { editable, applicationUUID, manifestUUID } = usePageEditMode();

  const fragment = editable
    ? interpolateRouteWithParams(PATHS.APPLICATIONS_PAGES_SHOW_SECTION, {
        application: {
          uuid: applicationUUID
        },
        page: {
          uuid: manifestUUID
        },
        section: {
          uuid: SECTIONS.PAGE_BUILDER
        }
      })
    : PATHS.HOME;

  const href = `${getConfig("WEB_CLIENT_ROOT")}${fragment}`;

  return (
    <a onClick={() => Analytics.track(CLICK_MADE_WITH)} href={href}>
      <div className="brand__bar pa2 z-2">
        <style jsx>{`
          .brand__bar {
            height: 33px;
            width: ${editable ? 166 : 186}px;
            position: fixed;
            visibility: visible;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/assets/${editable
              ? "edit"
              : "made"}_with.svg");
            color: ${COLORS.white};
            top: auto;
            left: 12px;
            right: auto;
            background-color: ${COLORS.white};
            border-radius: 3px;
            font-size: 12px;
            opacity: 1;
            line-height: 14px;
            text-decoration: none;
            transform: none;
            margin: 0;
            overflow: visible;
            white-space: nowrap;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
              0 1px 3px rgba(0, 0, 0, 0.1);
            cursor: pointer;
          }
          @media (max-width: ${PHONE}px) {
            .brand__bar {
              display: none;
              bottom: 24px;
            }
          }
          @media (min-width: ${PHONE + 1}px) {
            .brand__bar {
              display: inline-block;
              bottom: 12px;
            }
          }
        `}</style>
      </div>
    </a>
  );
};

MadeWith.propTypes = {
  router: PropTypes.object,
  subdomain: PropTypes.string,
  page: PropTypes.string
};

export default MadeWith;
