import React from "react";
import PropTypes from "prop-types";
import Button from "components/Form/fields/Button";
import classnames from "classnames";
import { BUTTON_CLASS_CONTEXT } from "utils/constants/ui";

const ModalActions = ({ actions, hasSlides, children }) => {
  const actionNodes =
    actions &&
    actions.length &&
    actions.map((action, ix) => {
      const { cta, onClick, loading, disabled, tag, target, href } = action;
      const buttonClasses = classnames(
        "fw6 br2 ph3 pv2 border-box input-reset ba b--black bg-transparent pointer f6 dib link items-center flex w-100 justify-center tc",
        {
          ml1: ix
        }
      );

      const actionClasses = {
        button: buttonClasses
      };
      if (!ix && actions && actions.length > 1) {
        actionClasses.contextClass = BUTTON_CLASS_CONTEXT.SECONDARY;
      }
      const buttonProps = {
        className: "",
        key: ix,
        onClick: onClick,
        copy: cta,
        disabled: disabled,
        loading: loading,
        classes: actionClasses
      };
      if (tag) {
        buttonProps.tag = tag;
      }
      if (target) {
        buttonProps.target = target;
      }
      if (href) {
        buttonProps.href = href;
      }

      return <Button {...buttonProps} />;
    });

  return (
    <div
      className={classnames(
        "absolute relative-ns mt3-ns center lh-copy w-100 left-0 right-0 bottom-0 pa0",
        {
          "pa3-ns": hasSlides
        }
      )}
    >
      <div
        key="controls"
        className="w-100 bg-white z-2 flex flex-column ph3 pb3 pt3 pt0-ns ph0-ns pb0-ns bt bn-ns hairline-1"
      >
        {children}
        <div className="w-100 flex flex-row">{actionNodes}</div>
      </div>
    </div>
  );
};

ModalActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      cta: PropTypes.string,
      onClick: PropTypes.func
    })
  )
};

export default ModalActions;
