import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Button from "components/Form/fields/Button";
import map from "lodash/map";
import SwipeableViews from "react-swipeable-views";
import SliderNavArrow from "components/SliderNavArrow";
import Slide from "components/Slide";
import ProgressDots from "components/ProgressDots";
import { SlidePropTypes } from "utils/propTypes";
import { BUTTON_CLASS_CONTEXT } from "utils/constants/ui";
import classnames from "classnames";

const DEFAULT_CLASSES = {
  container: "h-auto relative bg-white br2",
  actionsWrapper: "w-90 center"
};

export default class Carousel extends PureComponent {
  constructor(props) {
    super(props);
    this.classes = {
      ...DEFAULT_CLASSES,
      ...props.customClasses
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.progressIndex = this.progressIndex.bind(this);
    this.state = {
      index: 0
    };
  }

  sliderButtons() {
    const { slides } = this.props;
    const { index } = this.state;
    if (slides.length > 1) {
      const lastIndex = slides.length - 1;

      return (
        <div className="pv3 flex flex-row">
          <SliderNavArrow
            disabled={index === 0}
            step={-1}
            direction="left"
            onClick={this.progressIndex}
          />
          <ProgressDots index={index} count={slides.length} />
          <SliderNavArrow
            disabled={index === lastIndex}
            step={1}
            direction="right"
            onClick={this.progressIndex}
          />
        </div>
      );
    }
  }

  actionButtons() {
    const { actions } = this.props;
    if (actions && actions.length) {
      const actionNodes = actions.map((action, actionIx) => {
        const isSecondary = !actionIx && actions && actions.length > 1;
        const buttonClasses = {
          button: `z-1 fw6 br2 ph3 pv2 border-box input-reset ba b--black bg-transparent pointer f6 dib white ph4 flex-shrink-0 flex link ${
            actionIx ? "ml2" : ""
          }`
        };

        if (isSecondary) {
          buttonClasses.contextClass = BUTTON_CLASS_CONTEXT.SECONDARY;
        }
        return <Button key={actionIx} {...action} />;
      });
      return (
        <div
          className={classnames("w-100 pa2 dib justify-center flex pb3", {
            "justify-end": actions.length === 1,
            "justify-between": actions.length > 1
          })}
        >
          {actionNodes}
        </div>
      );
    }
  }

  progressIndex(step) {
    return () => {
      const slideCount = this.props.slides.length;
      const { index } = this.state;
      const newIndex = index + step;
      if (newIndex > -1 && newIndex < slideCount) {
        this.setState({ index: newIndex });
      }
    };
  }

  slideSection() {
    const swipeableProps = {
      className: "h-100 bg-white",
      onChangeIndex: this.handleChangeIndex,
      index: this.state.index
    };
    const { slides } = this.props;
    const slideNodes = map(slides, (slide, slideIx) => {
      return <Slide key={slideIx} {...slide} />;
    });

    return <SwipeableViews {...swipeableProps}>{slideNodes}</SwipeableViews>;
  }

  handleChange = (event, value) => {
    this.setState({
      index: value
    });
  };

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  render() {
    return (
      <div className={this.classes.container}>
        {this.slideSection()}
        <div className={this.classes.actionsWrapper}>
          {this.sliderButtons()}
          {this.actionButtons()}
        </div>
      </div>
    );
  }
}

Carousel.propTypes = {
  slides: PropTypes.arrayOf(SlidePropTypes).isRequired,
  slideConfig: PropTypes.shape({
    customStyles: PropTypes.shape({
      height: PropTypes.string
    })
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      href: PropTypes.string,
      tag: PropTypes.string
    })
  ),
  styles: PropTypes.object,
  customClasses: PropTypes.shape({
    container: PropTypes.string
  }),
  action: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  })
};
