import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Label from "./Label";
import map from "lodash/map";
import uniq from "lodash/uniq";
import get from "lodash/get";
import compact from "lodash/compact";
import startCase from "lodash/startCase";
import IconButton from "components/IconButton";
import { getIconForUID } from "components/FormerEditor/utils";
import { INFO_MODAL_ICON } from "./constants";
import { UUID_VALUE_KEY } from "utils/constants/form";
import { INPUT_CLASSES } from "utils/constants/ui";

const DEFAULT_CLASSES = {
  container: "",
  input: INPUT_CLASSES
};
const DEFAULT_ICON_STYLE = { right: 6, top: 6, pointerEvents: "none" };

/**
 * options = [{ uid: 'uid': label: 'Some option' }]
 */
const Select = ({
  label,
  value,
  valueKey,
  options,
  defaultValue,
  customClasses,
  onChange,
  setRef,
  disabled,
  validation,
  stateKey,
  showInfo,
  component,
  iconStyle,
  labelTransform,
  hideLabel
}) => {
  const selectRef = useRef(null);
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const selectValue = value || defaultValue;
  const Icon = getIconForUID("down");

  useEffect(() => {
    if (!value && selectRef && selectRef.current) {
      selectRef.current.selectedIndex = 0;
    }
  }, [value]);

  const labelTransformer = labelTransform || startCase;
  let nodes = [];
  const inputOptions = compact(options);
  if (inputOptions) {
    if (typeof inputOptions[0] === "object") {
      nodes = inputOptions.map(
        ({ uid, uuid, label, disabled: optDisabled, transformer }, optIx) => {
          const copy = transformer
            ? transformer(label)
            : labelTransformer(label);

          const optionVal = valueKey === UUID_VALUE_KEY ? uuid : uid;
          return (
            <option
              key={optIx}
              disabled={disabled || optDisabled}
              value={optionVal}
            >
              {copy}
            </option>
          );
        }
      );
    } else {
      nodes = map(uniq(inputOptions), (opt, optIx) => {
        const copy = labelTransformer(opt);
        const optProps = {
          key: optIx,
          value: opt
        };
        return <option {...optProps}>{copy}</option>;
      });
    }
  }

  const selectProps = {
    disabled,
    "className": `${classes.input}`,
    "type": "text",
    "aria-describedby": "name-desc",
    "ref": selectRef
  };

  if (onChange) {
    selectProps.value = selectValue;
    selectProps.onChange = onChange(stateKey, component);
  } else {
    selectProps.defaultValue = selectValue;
  }

  if (setRef) {
    selectProps.ref = (comp) => {
      setRef(stateKey, comp);
    };
  }

  let labelComp;
  if (label) {
    const labelProps = {
      label,
      error: Boolean(get(validation, "error", false))
    };
    labelComp = <Label {...labelProps} />;
  }
  let showInfoComp;
  if (showInfo) {
    const infoIconProps = {
      onClick: showInfo,
      iconType: "info",
      customClasses: {
        button: "flex ma0 pointer bn bg-white"
      },
      ...INFO_MODAL_ICON
    };
    if (infoIconProps.iconProps && infoIconProps.iconProps.size) {
      infoIconProps.iconProps.size = 20;
    }

    showInfoComp = <IconButton {...infoIconProps} />;
  }

  const inputComps = <select {...selectProps}>{nodes}</select>;

  return (
    <div className={classes.container}>
      {(labelComp || showInfoComp) && (
        <div className="flex flex-row">
          {!hideLabel && labelComp}
          {showInfoComp}
        </div>
      )}
      <div className="relative h-100">
        {inputComps}
        <div
          style={iconStyle || DEFAULT_ICON_STYLE}
          className="absolute top-0 bottom-0 flex items-center justify-center"
        >
          <div className="bg-transparent">
            <Icon size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueKey: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  stateKey: PropTypes.string,
  customClasses: PropTypes.object,
  component: PropTypes.string,
  disabled: PropTypes.bool,
  validation: PropTypes.object,
  iconStyle: PropTypes.object,
  // Expected to be an Array of primitive values
  // - not a collection of objects
  options: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  showInfo: PropTypes.func,
  setRef: PropTypes.func,
  labelTransform: PropTypes.func,
  hideLabel: PropTypes.bool
};

Select.defaultProps = {
  // onChange: (key, component) => {
  //   return (evt) => {
  //     console.log('**** Select value', evt.currentTarget.value)
  //   }
  // }
};

export default Select;
