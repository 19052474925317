import React from "react";
import { setHrefWithType, HREF_TYPE } from "utils/url";
import classnames from "classnames";

const DEFAULT_CLASSES = {
  container: "mt3 pa3 bt bb hairline-1 f6 lh-copy",
  copy: "f6",
  link: "f6 link color-primary fw5 link__decoration--hover"
};

const SupportSection = ({ url, email, phone, customClasses }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const contactLinks = [];
  if (url) {
    contactLinks.push({
      copy: "support site",
      hrefType: HREF_TYPE.LINK,
      href: url
    });
  }
  if (email) {
    contactLinks.push({
      copy: "email",
      hrefType: HREF_TYPE.MAILTO,
      href: email
    });
  }
  if (phone) {
    contactLinks.push({
      copy: "phone",
      hrefType: HREF_TYPE.TEL,
      href: phone
    });
  }

  return contactLinks.length ? (
    <div className={classes.container}>
      <span className={classes.copy}>
        If you have any questions, we can be contacted by{" "}
      </span>
      {contactLinks.map(({ copy, hrefType, href }, linkIx) => {
        const isFirst = linkIx === 0;
        const isOnly = contactLinks.length === 1;
        const isLast = linkIx === contactLinks.length - 1;
        const hrefWithType = setHrefWithType({ href, hrefType });
        // const isMiddle = !isOnly && !isLast && !isFirst;
        const isMultiLast = !isOnly && isLast;
        let value = copy;
        if (isMultiLast) {
          value = ` and ${copy}.`;
        } else if (!isOnly && !isFirst) {
          value = `, ${copy}`;
        } else if (isFirst && !isOnly) {
          value = copy;
        } else if (isLast) {
          value = ` ${copy}.`;
        }

        return (
          <span key={linkIx} className="di">
            <a href={hrefWithType} className={classes.link}>
              {value}
            </a>
          </span>
        );
      })}
    </div>
  ) : null;
};

export default SupportSection;
