import React from "react";
import PropTypes from "prop-types";
import componentStyles from "./componentStyles";

const Slide = ({ title, header, subheader, image, tertiary, children }) => {
  const imageClass = `slide__image slide__image--${image}`;

  // Add top padding for when the mobile nav bar is present
  return (
    <div className="permissions__carousel--slide tl pt3">
      <div className="w-90 center">
        {image && (
          <div className="slide__container bg-white center w-100 relative">
            <div className={imageClass} />
          </div>
        )}
        <div className="lh-title f3 mt3 mb2 fw6 f3-ns center pt2">{title}</div>
        <div className="center">
          <div className="lh-copy f5 f4-ns center">{header}</div>
          {subheader && (
            <div className="lh-copy f6 f5-ns pv2 center">{subheader}</div>
          )}
          {tertiary && (
            <div className="lh-copy mt2 mb5 i f6 center">{tertiary}</div>
          )}
          {children}
        </div>
      </div>
      <style global jsx>
        {componentStyles}
      </style>
    </div>
  );
};

Slide.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  tertiary: PropTypes.string,
  image: PropTypes.string
};

export default Slide;
