import React from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import { COLORS } from "utils/styles";
export const ICON_DIMENSIONS = 80;

const BrandIconHeader = ({ src, icon }) => {
  const Icon = getIconForUID(icon);

  return (
    <div
      style={{ height: ICON_DIMENSIONS, width: ICON_DIMENSIONS }}
      className="center br-100 hairline-1 ba pa2 overflow-hidden absolute top-0 left-0 right-0 bg-white"
    >
      {src ? (
        <img
          alt={`Brand logo for ${src}`}
          style={{
            maxWidth: "100%",
            maxHeight: "100%"
          }}
          src={src}
        />
      ) : Icon ? (
        <div className="flex justify-center items-center h-100">
          <Icon size={ICON_DIMENSIONS / 2} color={COLORS.blue} />
        </div>
      ) : null}
    </div>
  );
};

export default BrandIconHeader;
