import React, { Fragment } from "react";

const HeaderContent = ({ title, body, footer }) => (
  <Fragment>
    {title && <div className="f2 fw6">{title}</div>}
    {body &&
      body.map((bodyCopy, bodyCopyIx) => {
        return (
          <div className="f5 pt2 lh-copy" key={bodyCopyIx}>
            {bodyCopy}
          </div>
        );
      })}
    {footer &&
      footer.map((footerCopy, footerCopyIx) => {
        return (
          <div className="f6 pt2 lh-copy" key={footerCopyIx}>
            {footerCopy}
          </div>
        );
      })}
  </Fragment>
);

export default HeaderContent;
