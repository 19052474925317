import flatten from "lodash/flatten";
import compact from "lodash/compact";
import { ENV } from "utils/constants/env";
import { getQueryEmbedEnabled } from "utils/embed";
const CONFIG_TRACKING_KEYS = ["route", "subdomain", "alias"];

/**
 * Extend event.data with every key:val pair of data
 * @param {Object} event
 * @param {Object} data
 */
const extendEvent = (event, data) => {
  const extended = { ...event };

  for (const key in data) {
    extended.data[key] = data[key];
  }
  return extended;
};

/**
 * Extend tracking payloads with certain fields
 * @param {Array} events - array of events
 * @param {Object} data - fields to extend event data with
 * @param {Array} eventNames - specific eventNames to target with the fields - defaults to all
 */
export const extendTrackingEvents = (events, data = {}, eventNames = []) => {
  let result;
  if (eventNames.length) {
    result = eventNames.map((eventName) => {
      return events.map((event) => {
        const shouldExtend = event.event === eventName;
        if (shouldExtend) {
          return extendEvent(event, data);
        } else {
          return event;
        }
      });
    });
  } else {
    result = events.map((event) => extendEvent(event, data));
  }

  return compact(flatten(result));
};

export const getTrackingMode = (isTest) => (isTest ? ENV.TEST : ENV.PRODUCTION);

export const getPageFactoryFormTracking = ({
  manifest,
  /**
   * Note: config is the response shape of getPageFactoryFormTracking
   */
  config
}) => {
  const result = {};
  if (manifest) {
    result.manifest_id = manifest.id;
  } else {
    console.warn(
      "No manifest id for page. Note: ok for checkout status pages."
    );
  }

  CONFIG_TRACKING_KEYS.forEach((configKey) => {
    const configVal = config[configKey];
    if (configVal) {
      result[configKey] = configVal;
    }
  });

  result.embed = getQueryEmbedEnabled(config.query);

  return result;
};
