import React from "react";
import Button from "components/Form/fields/Button";
import classnames from "classnames";
import { UI_THEME } from "utils/constants/ui";

export const DEFAULT_CLASSES = {
  container:
    "w-100 flex flex-column flex-row-ns justify-between ba br2 hairline-1 pa3 items-start items-center-ns bg-white",
  content: "flex flex-column",
  header: "f4 fw6",
  subheader: "f5 lh-copy",
  actionsWrapper: "pt2 pl2-ns pt0-ns",
  actionsRow: "flex flex-row"
};

const SlimCTA = ({
  header,
  subheader,
  actions,
  extraActions,
  customClasses,
  children
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  let actionNodes = [];
  const hasActions = Array.isArray(actions) && actions.length > 0;
  if (hasActions) {
    actionNodes = actions.map((action, actionIx) => {
      return (
        <div key={actionIx} className={actionIx ? "pl2" : ""}>
          <Button theme={UI_THEME.SLIM} {...action} />
        </div>
      );
    });
  }
  const hasExtraActions =
    Array.isArray(extraActions) && extraActions.length > 0;
  if (hasExtraActions) {
    actionNodes = [...actionNodes, ...extraActions];
  }

  const actionsContent = actionNodes.length ? (
    <div className={classes.actionsWrapper}>
      <div className={classes.actionsRow}>{actionNodes}</div>
    </div>
  ) : null;
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {header && <div className={classes.header}>{header}</div>}
        {subheader && (
          <div
            className={classnames(classes.subheader, {
              pt2: header
            })}
          >
            {subheader}
          </div>
        )}
      </div>
      {actionsContent}
      {children}
    </div>
  );
};

export default SlimCTA;
