import React, { useState, useRef, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import ReactDOMServer from "react-dom/server";
import { useFormikContext } from "formik";
import { useDebounce } from "utils/hooks";
import get from "lodash/get";
import { getReducedHTMLText } from "utils/editor";

const DEBOUNCE = 500;

const Editor = ({ children, sync }) => {
  const [firstMount, setFirstMount] = useState(false);
  const { touched, setFieldValue, setFieldTouched } = useFormikContext();

  const fieldName = sync.name;
  const fieldTouched = get(touched, fieldName);

  const childString = ReactDOMServer.renderToStaticMarkup(children);

  const [nodeValue, setNodeValue] = useState(childString);

  const text = useRef(childString);

  const handleChange = (evt) => {
    const updatedHtml = getReducedHTMLText(evt.target.value);

    text.current = updatedHtml;

    setNodeValue(updatedHtml);
  };

  const setAndSync = (value) => {
    setFieldValue(fieldName, value);

    if (!fieldTouched) {
      setFieldTouched(fieldName, true);
    }
    /**
     * Sync to parent field if a path is provided
     */
    if (sync && sync.path) {
      setFieldValue(sync.path, value);
    }
  };

  useEffect(() => {
    if (firstMount) {
      setAndSync(getReducedHTMLText(nodeValue));
    } else {
      setFirstMount(true);
    }
  }, [useDebounce(nodeValue, DEBOUNCE)]);

  const handleBlur = () => {
    const reducedValue = getReducedHTMLText(text.current);
    if (process.env.NODE_ENV === "development") {
      console.log("*** Editable value", reducedValue);
    }
    setAndSync(reducedValue);
    setFieldValue(fieldName, reducedValue);
  };

  return (
    <ContentEditable
      html={text.current}
      onBlur={handleBlur}
      onChange={handleChange}
      className="b-inner--primary-fade"
    />
  );
};

const EditableContent = (props) =>
  props.editable && props.sync && props.sync.name ? (
    <Editor {...props} />
  ) : props.children ? (
    props.children
  ) : null;

export default EditableContent;
