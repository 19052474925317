import React, { Fragment } from "react";
import BrandIconHeader, {
  ICON_DIMENSIONS
} from "components/Checkout/BrandIconHeader";
import SupportSection from "components/Checkout/SupportSection";
import HeaderContent from "components/Checkout/HeaderContent";

const CheckoutInfoNotice = ({ account, content }) => {
  const iconProps = {
    icon: "alert"
  };
  if (account && account.business_icon) {
    iconProps.src = account.business_icon;
  }

  return (
    <Fragment>
      <BrandIconHeader {...iconProps} />
      <div style={{ paddingTop: ICON_DIMENSIONS / 2 }}>
        <div className="w-100 hairline-1 ba shadow-0 pv5 ph4-ns ph3 br3">
          <HeaderContent {...content} />
          {account && (
            <SupportSection
              url={account && account.supportUrl}
              email={account && account.supportEmail}
              phone={account && account.supportPhone}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutInfoNotice;
