import React from "react";
import PropTypes from "prop-types";
import { SlidePropTypes } from "utils/propTypes";
import Carousel from "components/Carousel";
import classnames from "classnames";

const ModalContent = ({ header, body, slides, children }) => {
  const hasSlides = slides && slides.length;

  const carouselProps = {
    slides
  };

  let content = null;
  if (children) {
    if (typeof children === "function") {
      content = children({ hasSlides });
    } else {
      content = React.Children.map(children, () => {
        return React.cloneElement(children, { hasSlides });
      });
    }
  }

  return (
    <div
      className={classnames("w-100 h-100 h-auto-ns center", {
        "ph3 pb3": !hasSlides
      })}
    >
      {header && <div className="f3 lh-title fw5 tl">{header}</div>}
      {body && <div className="f5 lh-copy fw4 tl pt3">{body}</div>}
      {Array.isArray(slides) && slides.length > 0 && (
        <Carousel {...carouselProps} />
      )}
      {content}
    </div>
  );
};

ModalContent.propTypes = {
  header: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  slides: PropTypes.arrayOf(SlidePropTypes),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

export default ModalContent;
