import React from "react";
import ENTITIES from "components/FormerEditor/common/constants/entity";
import { Formik } from "formik";
import PageFactory from "components/FormerEditor/PageFactory";
import { recomputePricing } from "utils/pricing";
import { validateFormFieldGroups } from "utils/manifest";

const ENTITY_ON_CHANGE = {
  [ENTITIES.PRICING_TABLE]: recomputePricing,
  [ENTITIES.FEATURE_COMPARISON]: recomputePricing
};

/**
 * @param {Object} props - server side fetched configuration to be used when initializing the form
 */
const PageFactoryForm = (props) => {
  return (
    <Formik
      initialValues={props.data}
      validate={async (values) => {
        const result = await validateFormFieldGroups({
          values
        });

        return result;
      }}
    >
      {({ values }) => (
        <PageFactory
          tracking={props.tracking}
          data={values}
          entityAfterChanges={ENTITY_ON_CHANGE}
        />
      )}
    </Formik>
  );
};

export default PageFactoryForm;
