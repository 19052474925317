import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { COLORS } from "utils/styles";
import omit from "lodash/omit";
import classnames from "classnames";
import { IoIosArrowForward, IoIosArrowBack } from "@hacknug/react-icons/io";
const arrows = {
  right: IoIosArrowForward,
  left: IoIosArrowBack
};

const dimension = 32;
class SliderNavArrow extends PureComponent {
  render() {
    const { direction, onClick, step, disabled } = this.props;
    const Arrow = arrows[direction];
    const arrowContainerStyles = {
      height: `${dimension}px`,
      width: `${dimension}px`,
      outline: "none"
    };
    const containerProps = {
      ...omit(this.props, ["currentSlide", "slideCount"]),
      onClick: onClick(step),
      disabled
    };
    const classes = classnames("bn bg-transparent pa0 ma0", {
      "o-50": disabled,
      "pointer": !disabled
    });

    return (
      <button
        {...containerProps}
        className={classes}
        style={arrowContainerStyles}
      >
        <Arrow size={dimension} color={COLORS.primary} />
      </button>
    );
  }
}

SliderNavArrow.propTypes = {
  disabled: PropTypes.bool,
  direction: PropTypes.string,
  step: PropTypes.number,
  onClick: PropTypes.func
};

SliderNavArrow.defaultProps = {
  onClick: () => {},
  disabled: false
};

export default SliderNavArrow;
