import React, { useState, useEffect } from "react";
import BrandIconHeader, {
  ICON_DIMENSIONS
} from "components/Checkout/BrandIconHeader";
import Button from "components/Form/fields/Button";
import HeaderContent from "components/Checkout/HeaderContent";
import { captureException, reduceDisplayErrorMessage } from "utils/error";

const DEFAULT_ERROR = {
  title: "Whoops!",
  body: [
    "There was an error loading this page.",
    "Please refresh to try again."
  ]
};

const ErrorNotice = ({ error, onClick, actionCopy, hideActions, children }) => {
  const [loading, setLoading] = useState(false);
  const displayError = { ...DEFAULT_ERROR, ...error };

  useEffect(() => {
    const message = reduceDisplayErrorMessage(displayError);
    if (message) {
      captureException(new Error(message));
    }
  }, []);

  return (
    <div className="w-100 relative">
      <BrandIconHeader icon="alarm" />
      <div style={{ paddingTop: ICON_DIMENSIONS / 2 }}>
        <div className="w-100 hairline-1 ba shadow-0 pa4 br2 bg-white">
          <div className="pt3">
            <HeaderContent {...displayError} />
          </div>
          {!hideActions && (
            <div className="w-100 justify-center items-center flex pt4">
              {children}
              <Button
                loading={loading}
                onClick={
                  onClick
                    ? onClick
                    : () => {
                        // Reload the current page by default
                        if (typeof window !== "undefined") {
                          setLoading(true);
                          window.location = window.location;
                        }
                      }
                }
                copy={actionCopy || "Refresh"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorNotice;
