import React from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import { THEMES } from "utils/constants/ui";
import EditableContent from "components/EditableContent";

const THEME_CLASS = {
  [THEMES.ERROR]: "b--error",
  [THEMES.WARNING]: "b--warning"
};
const THEME_ICON = {
  [THEMES.ERROR]: "alert",
  [THEMES.WARNING]: "alert"
};

const DEFAULT_CLASSES = {
  container:
    "w-100 flex flex-row ba br2 hairline-1 pa2 items-start bg-content--secondary",
  icon: "flex flex-shrink-0",
  copy: "f7 f6-ns lh-copy pl2 word-break"
};

const InfoNotice = ({
  edit,
  name,
  copy,
  icon,
  theme,
  hideIcon,
  customClasses,
  children
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const themeClass = THEME_CLASS[theme] || "";
  const themeIcon = THEME_ICON[theme] || icon || "help";
  const Icon = getIconForUID(themeIcon);

  return (
    <div className={`${classes.container} ${themeClass}`}>
      {!hideIcon && (
        <div style={{ marginTop: 1 }} className={classes.icon}>
          <Icon size={16} />
        </div>
      )}
      <div className={classes.copy}>
        <EditableContent
          editable={edit}
          sync={{
            name
          }}
        >
          {copy}
        </EditableContent>
      </div>
      {children}
    </div>
  );
};

export default InfoNotice;
