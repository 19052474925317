import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import assign from "lodash/assign";
import { DURATION } from "utils/styles";
const DEFAULT_CLASSES = {
  container: "flex flex-row justify-center items-center w-100"
};
const DEFAULT_DIMENSIONS = {
  active: 12,
  inactive: 8
};

export default class ProgressDots extends PureComponent {
  static propTypes = {
    index: PropTypes.number,
    count: PropTypes.number.isRequired,
    customClasses: PropTypes.object,
    dimensions: PropTypes.shape({
      active: PropTypes.number,
      inactive: PropTypes.number
    })
  };

  render() {
    const { customClasses, count, dimensions } = this.props;
    const { index } = this.props;
    let nodes = [];
    const classes = assign({}, DEFAULT_CLASSES, customClasses);

    for (let dotIx = 0; dotIx < count; dotIx++) {
      const active = index === dotIx;
      const dotClasses = classnames("progressDot br-100 flex mh1", {
        "bg-black-30": !active,
        "bg-primary": active
      });

      const dotDimension = active ? dimensions.active : dimensions.inactive;
      const dotStyles = {
        width: dotDimension,
        height: dotDimension
      };

      nodes.push(
        <span key={dotIx}>
          <div style={dotStyles} className={dotClasses} />
          <style jsx>{`
            .progressDot {
              -webkit-transition: width ${DURATION}s, height ${DURATION}s,
                background-color ${DURATION}s, -webkit-transform ${DURATION}s;
              transition: width ${DURATION}s, height ${DURATION}s,
                background-color ${DURATION}s, transform ${DURATION}s;
            }
          `}</style>
        </span>
      );
    }

    return <div className={classes.container}>{nodes}</div>;
  }
}

ProgressDots.defaultProps = {
  dimensions: DEFAULT_DIMENSIONS
};
