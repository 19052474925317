/**
 *  Valid sources are:
 *  - local_file_system - Default
 *  - url - Default
 *  - imagesearch - Default
 *  - facebook - Default
 *  - instagram - Default
 *  - googledrive - Default
 *  - dropbox - Default
 *  - webcam - Uses device menu on mobile. Not currently supported in Safari and IE.
 *  - video - Uses device menu on mobile. Not currently supported in Safari and IE.
 *  - audio - Uses device menu on mobile. Not currently supported in Safari and IE.
 *  - box
 *  - github
 *  - gmail
 *  - picasa
 *  - onedrive
 *  - onedriveforbusiness
 *  - customsource - Configure this in your Filestack Dev Portal.
 */
const ALLOWED_IMAGE_PICKER_SOURCES = [
  "local_file_system",
  // "unsplash",
  "instagram",
  "googledrive",
  // "dropbox",
  "facebook",
  "url"
  // "imagesearch"
];

const ALLOWED_FILE_PICKER_SOURCES = [
  "local_file_system",
  "url",
  "googledrive",
  // "dropbox",
  "box",
  "github",
  "gmail",
  "onedrive",
  "onedriveforbusiness"
];

export const DEFAULT_UPLOAD_SOURCES = [
  "local_file_system",
  "googledrive",
  // "dropbox",
  "box",
  "onedrive",
  "onedriveforbusiness",
  "github"
];

/**
 * Allowed picker sources
 */
export const PICKER_SOURCES = {
  IMAGES: ALLOWED_IMAGE_PICKER_SOURCES,
  FILES: ALLOWED_FILE_PICKER_SOURCES
};

export const FILE_EXTENSIONS = {
  PNG: "png",
  JPEG: "jpeg"
};

/**
 * Accept file types
 */
export const MIMETYPE = {
  IMAGE_ALL: "image/*",
  IMAGE_PNG: `image/${FILE_EXTENSIONS.PNG}`,
  IMAGE_JPEG: `image/${FILE_EXTENSIONS.JPEG}`,
  PDF: ".pdf",
  VIDEO: "video/*",
  APPLICATION: "application/*",
  TEXT: "text/*"
};

const ALLOWED_IMAGE_FILE_TYPES = [MIMETYPE.IMAGE_PNG, MIMETYPE.IMAGE_JPEG];
const ALLOWED_FILES_FILE_TYPES = [
  MIMETYPE.PDF,
  MIMETYPE.VIDEO,
  MIMETYPE.APPLICATION,
  MIMETYPE.TEXT
];

export const DEFAULT_UPLOAD_TYPES = Object.values(MIMETYPE);

// https:filestack.github.io/filestack-js/interfaces/pickeroptions.html#accept
export const ALLOWED = {
  IMAGES: ALLOWED_IMAGE_FILE_TYPES,
  FILES: ALLOWED_FILES_FILE_TYPES
};

/**
 * File sizes
 */
export const TWO_MB_LIMIT = 1024 * 1024 * 2;
export const TEN_MB_LIMIT = 1024 * 1024 * 10;

export const PICKER_OPTIONS = {
  IMAGES: {
    fromSources: PICKER_SOURCES.IMAGES,
    accept: ALLOWED.IMAGES,
    maxSize: TWO_MB_LIMIT,
    maxFiles: 1
  },
  FILES: {
    fromSources: PICKER_SOURCES.FILES,
    accept: ALLOWED.FILES,
    maxSize: TEN_MB_LIMIT * 2,
    maxFiles: 1
  }
};

export const PROMPTS = {
  IMAGES: {
    upload: {
      header: "Upload an image for your product."
    },
    error: {
      header: "There was an error preparing for upload.",
      subheader: "Refresh to try again."
    }
  },
  FILES: {
    upload: {
      header: "Upload a file."
    },
    error: {
      header: "There was an error preparing for upload.",
      subheader: "Refresh to try again."
    }
  }
};

const FILESTACK = "filestack";
const AWS = "aws";

export const ATTACHMENT_SERVICE = {
  FILESTACK,
  AWS
};

/**
 * Imagery for seller assets
 */
const CONTENT = "content";
/**
 * Platform data
 */
const DATA = "data";
/**
 * Hosted download content for sellers customers
 */
const DOWNLOAD = "download";
/**
 * Hosted upload content for sellers cusomters
 */
const UPLOAD = "upload";

export const ATTACHMENT_CONTEXT_TYPE = {
  CONTENT,
  DATA,
  DOWNLOAD,
  UPLOAD
};
