import css from "styled-jsx/css";
import IMAGES from "utils/constants/image";

export default css`
  :global(.slide__image--${IMAGES.FEATURE_COMPARISON}::after) {
    background-image: url("/assets/${IMAGES.FEATURE_COMPARISON}.png");
  }
  :global(.slide__image--${IMAGES.PAYMENTS_SECURE}::after) {
    background-image: url("/assets/${IMAGES.PAYMENTS_SECURE}.png");
  }
  :global(.slide__image--${IMAGES.PRICING_TABLE_ISO}::after) {
    background-image: url("/assets/${IMAGES.PRICING_TABLE_ISO}.png");
  }
  :global(.slide__image--${IMAGES.LINK_CHECKOUT}::after) {
    background-image: url("/assets/${IMAGES.LINK_CHECKOUT}.png");
  }
  :global(.slide__image--${IMAGES.PUBLISH_PAGE_PREVIEW}::after) {
    background-image: url("/assets/${IMAGES.PUBLISH_PAGE_PREVIEW}.png");
  }
  :global(.slide__image--${IMAGES.PUBLISH_PAGE_EDITOR}::after) {
    background-image: url("/assets/${IMAGES.PUBLISH_PAGE_EDITOR}.png");
  }
  :global(.slide__image--${IMAGES.BLOCKS_PICKER}::after) {
    background-image: url("/assets/${IMAGES.BLOCKS_PICKER}.svg");
  }

  :global(.slide__image--${IMAGES.PAYMENT_LINK_BUILD}::after) {
    background-image: url("/assets/${IMAGES.PAYMENT_LINK_BUILD}.svg");
  }
  :global(.slide__image--${IMAGES.PAYMENT_LINK_OMNICHANNEL}::after) {
    background-image: url("/assets/${IMAGES.PAYMENT_LINK_OMNICHANNEL}.svg");
  }
  :global(.slide__image--${IMAGES.PAYMENT_LINK_PAYMENT_METHODS}::after) {
    background-image: url("/assets/${IMAGES.PAYMENT_LINK_PAYMENT_METHODS}.svg");
  }

  // Link cases
  :global(.slide__image--${IMAGES.LINK_USE_CASE_EMAIL}::after) {
    background-image: url("/assets/${IMAGES.LINK_USE_CASE_EMAIL}.png");
  }
  :global(.slide__image--${IMAGES.LINK_USE_CASE_VIDEO}::after) {
    background-image: url("/assets/${IMAGES.LINK_USE_CASE_VIDEO}.png");
  }
  :global(.slide__image--${IMAGES.LINK_USE_CASE_SOCIAL}::after) {
    background-image: url("/assets/${IMAGES.LINK_USE_CASE_SOCIAL}.png");
  }
  :global(.slide__image--${IMAGES.LINK_USE_CASE_CONTACTLESS}::after) {
    background-image: url("/assets/${IMAGES.LINK_USE_CASE_CONTACTLESS}.png");
  }
  :global(.slide__image--${IMAGES.LINK_USE_CASE_SMS}::after) {
    background-image: url("/assets/${IMAGES.LINK_USE_CASE_SMS}.png");
  }

  :global(.slide__image--${IMAGES.PRODUCT_FEATURE_SHOW}::after) {
    background-image: url("/assets/${IMAGES.PRODUCT_FEATURE_SHOW}.svg");
  }
  :global(.slide__image--${IMAGES.PRODUCT_FEATURE_TOOLTIP}::after) {
    background-image: url("/assets/${IMAGES.PRODUCT_FEATURE_TOOLTIP}.svg");
  }
  :global(.slide__image--${IMAGES.EDITOR_BLOCK_SETTINGS}::after) {
    background-image: url("/assets/${IMAGES.EDITOR_BLOCK_SETTINGS}.svg");
  }
  :global(.slide__image--${IMAGES.EDITOR_EDIT_BLOCK}::after) {
    background-image: url("/assets/${IMAGES.EDITOR_EDIT_BLOCK}.svg");
  }
  :global(.slide__image--${IMAGES.EDITOR_PUBLISH}::after) {
    background-image: url("/assets/${IMAGES.EDITOR_PUBLISH}.svg");
  }
  :global(.slide__image--${IMAGES.EDITOR_SIDE_NAV}::after) {
    background-image: url("/assets/${IMAGES.EDITOR_SIDE_NAV}.svg");
  }
  :global(.slide__image--onboarding_set_right_goals::after) {
    background-image: url("/assets/onboarding_set_right_goals.png");
  }
  :global(.slide__image--onboarding_supercharged_development::after) {
    background-image: url("/assets/onboarding_supercharged_development.png");
  }
  :global(.slide__image--devplan_setup_complete::after) {
    background-image: url("/assets/cta__rocket--clip.png");
  }
  :global(.slide__image--devplan_buildCommunity::after) {
    background-image: url("/assets/devplan_buildCommunity.png");
  }
  :global(.slide__image--onboarding_schedule_drag::after) {
    background-image: url("/assets/onboarding_schedule_drag.png");
  }
  :global(.slide__image--onboarding_add_remove::after) {
    background-image: url("/assets/onboarding_add_remove.png");
  }
  :global(.slide__image--onboarding_search_and_filter::after) {
    background-image: url("/assets/onboarding_search_and_filter.png");
  }
  :global(.slide__image--onboarding_quiz_preferences::after) {
    background-image: url("/assets/onboarding_quiz_preferences.png");
  }
  :global(.slide__image--dashboard_first_calendar::after) {
    background-image: url("/assets/dashboard_first_calendar.png");
  }
  :global(.slide__image--dashboard_first_congratulations::after) {
    background-image: url("/assets/dashboard_first_congratulations.png");
  }
  :global(.slide__image--dashboard_first_adjust_plan::after) {
    background-image: url("/assets/dashboard_first_adjust_plan.png");
  }
  :global(.slide__image--evaluations__answerGuide::after) {
    background-image: url("/assets/evaluations__answerGuide.png");
  }
`;
