import React from "react";
import EditableContent from "components/EditableContent";
import PropTypes from "prop-types";

const Label = ({
  label,
  error,
  customClasses,
  formatter,
  required,
  name,
  edit
}) => {
  let labelClasses = customClasses;
  if (error) {
    labelClasses += " color-error";
  }
  const content = formatter ? formatter(label) : label;

  return (
    <label htmlFor={label} className={labelClasses}>
      <EditableContent editable={edit} sync={{ name }}>
        {content}
      </EditableContent>
      {required && !edit && <sup className="f7 fw4">*</sup>}
    </label>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  formatter: PropTypes.func,
  customClasses: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  edit: PropTypes.bool,
  required: PropTypes.bool
};

Label.defaultProps = {
  customClasses: "field__label fw5 db",
  error: false
};

export default Label;
